import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Autocomplete, LoadScript, Libraries } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import axios from "axios";
import {
  fetchVicinityUserId,
  fetchPosts,
  handleDeletePost,
  sendNotificationToolNotification,
} from "../../firestore/queries";
import { EventPost } from "./Events";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CheckCircle } from "@mui/icons-material";

const placesLibs: Libraries = ["places"];
const googleMapsApiKey =
  process.env.REACT_APP_ENV === "production"
    ? "AIzaSyBF90b6SXXIUcPhJAIHHM69emhKfX0JvLo"
    : "AIzaSyBF90b6SXXIUcPhJAIHHM69emhKfX0JvLo";
const baseUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://us-central1-vicinity-e7a18.cloudfunctions.net"
    : "https://us-central1-vicinitytest-523d5.cloudfunctions.net";

const NotificationToolPage = () => {
  const [vicinityUserId, setVicinityUserId] = useState<string | null>(null);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [centerPoint, setCenterPoint] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [radius, setRadius] = useState(50);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [locationSelected, setLocationSelected] = useState(false);
  const [postId, setPostId] = useState("");
  const [bypassNotificationDocument, setBypassNotificationDocument] =
    useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [posts, setPosts] = useState<EventPost[]>([]);
  const [displayName, setDisplayName] = useState("");
  const [sendAsInAppMessage, setSendAsInAppMessage] = useState(false);
  const [admImageUrl, setAdmImageUrl] = useState("");
  const [admTitle, setAdmTitle] = useState("");
  const [admSubtitle, setAdmSubtitle] = useState("");
  const [admBody, setAdmBody] = useState("");
  const [admDestination, setAdminDestination] = useState("");
  const [admDestinationButtonLabel, setAdmDestinationButtonLabel] =
    useState("");
  const [showPostsViewer, setShowPostsViewer] = useState(false);

  const placesAutocompleteRef = useRef<any>(null);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 1000,
  });

  useEffect(() => {
    if (showPostsViewer) {
      fetchVicinityUserId().then((vicinityUserId) => {
        setVicinityUserId(vicinityUserId);
        fetchPostsWrapper(vicinityUserId);
      });
    }
  }, [showPostsViewer]);

  useEffect(() => {
    if (postId) {
      const postIndex = posts.findIndex((post) => post.id === postId);
      if (postIndex !== -1) {
        const updatedPosts = [...posts];
        const [matchedPost] = updatedPosts.splice(postIndex, 1);
        updatedPosts.unshift(matchedPost);
        setPosts(updatedPosts);
      }
    }
  }, [postId, posts]);

  const fetchPostsWrapper = async (
    vicinityUserId: string,
    displayName?: string
  ) => {
    setLoading(true);
    const posts: EventPost[] = await fetchPosts(
      undefined,
      vicinityUserId,
      displayName
    );
    setLoading(false);
    setPosts(
      posts
        .filter((post) => post.id) // Filter out posts without an "id"
        .sort(
          (a, b) =>
            new Date((b.dateCreated as any)._seconds * 1000).getTime() -
            new Date((a.dateCreated as any)._seconds * 1000).getTime()
        )
    );
  };

  const handleSearchByDisplayName = () => {
    if (vicinityUserId) {
      fetchPostsWrapper(vicinityUserId, displayName);
    }
  };

  const handleAddPostId = (postId: string) => {
    setPostId(postId);
    window.scrollTo({
      top: document.getElementById("notificationToolHeading")?.offsetTop,
      behavior: "smooth",
    });
  };

  const handleDeletePostWrapper = (postId: string) => {
    handleDeletePost(
      postId,
      setLoading,
      setResponseMessage,
      () => fetchPostsWrapper(vicinityUserId ?? ""),
      () => setPostId("")
    );
  };

  const onLoad = (autocomplete: any) => {
    placesAutocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = async () => {
    const place = await placesAutocompleteRef.current.getPlace();
    if (place?.geometry) {
      const { lat, lng } = place.geometry.location;
      setCenterPoint({ lat: lat(), lng: lng() });
      setValue(place.formatted_address);
      setLocationSelected(true);
    }
  };

  const handleCancelLocation = () => {
    setCenterPoint(null);
    setLocationSelected(false);
    setValue("");
  };

  const handleSendNotification = async () => {
    if (!title || !message) {
      setResponseMessage("Error: Title and message are required.");
      return;
    }
    if (title.length > 50 || message.length > 150) {
      setResponseMessage(
        "Error: Title must be at most 50 characters and message at most 150 characters."
      );
      return;
    }
    if (!centerPoint) {
      setOpenWarningModal(true);
      return;
    }
    if (sendAsInAppMessage && !admBody) {
      setResponseMessage("Error: Body is required for In-App Message.");
      return;
    }
    sendNotification();
  };

  const sendNotification = async () => {
    setLoading(true);
    try {
      const payload = {
        title,
        message,
        centerPoint,
        radius,
        bypassNotificationDocument: sendAsInAppMessage
          ? false
          : bypassNotificationDocument,
        type: sendAsInAppMessage ? "adminNotificationMessage" : undefined,
        admImageUrl: sendAsInAppMessage ? admImageUrl : undefined,
        admTitle: sendAsInAppMessage ? admTitle : undefined,
        admSubtitle: sendAsInAppMessage ? admSubtitle : undefined,
        admBody: sendAsInAppMessage ? admBody : undefined,
        admDestination: sendAsInAppMessage ? admDestination : undefined,
        admDestinationButtonLabel: sendAsInAppMessage
          ? admDestinationButtonLabel
          : undefined,
      };
      const result = await sendNotificationToolNotification(payload);
      setResponseMessage(
        `Success: ${result.successes} notifications sent, ${result.failures} failures.`
      );
    } catch (error: any) {
      setResponseMessage(`Error: ${error.message}`);
    }
    setLoading(false);
    setOpenWarningModal(false);
  };

  const handleWarningModalClose = () => {
    setOpenWarningModal(false);
  };

  const handleWarningModalConfirm = () => {
    sendNotification();
  };

  const modules = {
    toolbar: false, // Disable toolbar for read-only mode
  };

  return (
    <Container>
      <Button
        onClick={() => {
          window.location.href = "/admin";
        }}
      >
        Back to Admin Page
      </Button>
      <Button
        onClick={() => {
          window.location.href = "/admin/events";
        }}
      >
        Go to Events Tool
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={sendAsInAppMessage ? 6 : 12}>
          <Paper style={{ padding: "16px", marginBottom: "16px" }}>
            <Typography variant="h4" gutterBottom id="notificationToolHeading">
              Notification Tool
            </Typography>
            {process.env.REACT_APP_ENV === "production" && (
              <Typography variant="body1" color="red" gutterBottom>
                PRODUCTION
              </Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendAsInAppMessage}
                      onChange={(e) => setSendAsInAppMessage(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Send as In-App Message"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Push Notification Title"
                  fullWidth
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  inputProps={{ maxLength: 50 }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Push Notification Message"
                  fullWidth
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 150 }}
                  required
                />
                <Typography variant="body2" color="textSecondary">
                  Want to add Emojis? Press ^ (Control) ⌘ (Command) Space or
                  Press ⊞ (Windows Logo Key) . (Period)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <TextField
                    label="Center Point Location"
                    fullWidth
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={!ready || locationSelected}
                  />
                </Autocomplete>
                {locationSelected && (
                  <Button
                    onClick={handleCancelLocation}
                    variant="outlined"
                    color="secondary"
                  >
                    Cancel Location
                  </Button>
                )}
                <Typography variant="body2" color="textSecondary">
                  If a center point location is not set the notification will be
                  sent to every user.
                </Typography>
              </Grid>
              {locationSelected && (
                <Grid item xs={12}>
                  <TextField
                    label="Radius in Miles (optional)"
                    fullWidth
                    type="number"
                    value={radius}
                    onChange={(e) => setRadius(Number(e.target.value))}
                  />
                </Grid>
              )}
              {!sendAsInAppMessage && (
                <Grid item xs={12} id="postIdForm">
                  <TextField
                    label="Post ID (optional)"
                    fullWidth
                    value={postId}
                    onChange={(e) => setPostId(e.target.value)}
                  />
                  <Typography variant="body2" color="textSecondary">
                    Attach a Post Id if you'd like a Post or EventPost to
                    display when the notification is opened
                  </Typography>
                </Grid>
              )}
              {sendAsInAppMessage && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6">In-App Message Fields</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Image URL (optional)"
                      fullWidth
                      value={admImageUrl}
                      onChange={(e) => setAdmImageUrl(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Title (optional)"
                      fullWidth
                      value={admTitle}
                      onChange={(e) => setAdmTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Subtitle (optional)"
                      fullWidth
                      value={admSubtitle}
                      onChange={(e) => setAdmSubtitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Body"
                      fullWidth
                      value={admBody}
                      onChange={(e) => setAdmBody(e.target.value)}
                      multiline
                      rows={4}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Destination (optional)"
                      fullWidth
                      value={admDestination}
                      onChange={(e) => setAdminDestination(e.target.value)}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value=""></option>
                      <option value="map">Map</option>
                      <option value="chats">Chats</option>
                      <option value="calendar">Calendar</option>
                      <option value="feed">Feed</option>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Destination Button Label (optional)"
                      fullWidth
                      value={admDestinationButtonLabel}
                      onChange={(e) =>
                        setAdmDestinationButtonLabel(e.target.value)
                      }
                    />
                  </Grid>
                </>
              )}
              {!sendAsInAppMessage && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bypassNotificationDocument}
                        onChange={(e) =>
                          setBypassNotificationDocument(e.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Skip Notification Center"
                  />
                  <Typography variant="body2" color="textSecondary">
                    If this is checked, only a push notification will be sent,
                    no NotificationCenter entry will be created.
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendNotification}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Send Notification"
                  )}
                </Button>
              </Grid>
              {responseMessage && (
                <Grid item xs={12}>
                  <Typography variant="h6" color="textSecondary">
                    {responseMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        {sendAsInAppMessage && (
          <Grid item xs={6}>
            <Paper
              style={{
                padding: "16px",
                marginBottom: "16px",
                marginTop: "200px",
                height: "896px",
                position: "relative",
                maxWidth: "414px",
              }}
            >
              {admDestination && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginBottom: "8px" }}
                >
                  The Destination button will take the User to: {admDestination}
                </Typography>
              )}
              {admImageUrl && (
                <div
                  style={{
                    height: "30%",
                    backgroundImage: `url(${admImageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
              )}
              {admTitle && (
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    marginTop: "8px",
                  }}
                >
                  {admTitle}
                </Typography>
              )}
              {admSubtitle && (
                <Typography
                  variant="subtitle1"
                  style={{ color: "darkgreen", marginTop: "4px" }}
                >
                  {admSubtitle}
                </Typography>
              )}
              {admBody && (
                <Typography
                  variant="body1"
                  style={{ color: "black", marginTop: "4px" }}
                >
                  {admBody}
                </Typography>
              )}
              <div
                style={{
                  width: "100%",
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "darkgreen",
                    color: "white",
                    width: admDestination ? "48%" : "96%",
                    textTransform: "none",
                  }}
                >
                  Close
                </Button>
                {admDestination && (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "darkgreen",
                      color: "white",
                      width: "48%",
                      textTransform: "none",
                    }}
                  >
                    {admDestinationButtonLabel || "Open"}
                  </Button>
                )}
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
      <Button
        onClick={() => setShowPostsViewer(true)}
        style={{ marginBottom: "16px" }}
      >
        View Posts Viewer
      </Button>
      {showPostsViewer && (
        <Paper style={{ padding: "16px", marginBottom: "16px" }}>
          <Typography variant="h5" gutterBottom>
            Posts Viewer
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Search by Display Name"
                fullWidth
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearchByDisplayName}
                disabled={loading}
                style={{ marginTop: "16px" }}
              >
                {loading ? <CircularProgress size={24} /> : "Search"}
              </Button>
            </Grid>
            {posts
              .filter((post) => post.id) // Filter out posts without an "id"
              .sort(
                (a, b) =>
                  new Date((b.dateCreated as any)._seconds * 1000).getTime() -
                  new Date((a.dateCreated as any)._seconds * 1000).getTime()
              )
              .map((post) => (
                <Grid item xs={12} key={post.id}>
                  <Paper style={{ padding: "16px", position: "relative" }}>
                    {post.id === postId && (
                      <>
                        <Typography variant="h6" color="green">
                          Attached Post
                        </Typography>
                        <CheckCircle
                          style={{
                            color: "green",
                            position: "absolute",
                            top: "16px",
                            right: "16px",
                            fontSize: "32px",
                          }}
                        />
                      </>
                    )}
                    {post.isEvent && (
                      <Typography variant="h6" color="primary">
                        Event
                      </Typography>
                    )}
                    {post.title && (
                      <Typography variant="h6">{post.title}</Typography>
                    )}
                    {post.place && (
                      <Typography variant="body2" color="textSecondary">
                        {post.place}
                      </Typography>
                    )}
                    {post.isEvent && post.startTime && (
                      <Typography variant="body2" color="textSecondary">
                        Start Time: {new Date(post.startTime).toLocaleString()}
                      </Typography>
                    )}
                    {typeof post.content === "object" ||
                    post.content.includes('"insert":') ? (
                      <ReactQuill
                        value={post.content}
                        readOnly={true}
                        theme="bubble"
                        modules={modules}
                      />
                    ) : (
                      <Typography variant="body1">{post.content}</Typography>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddPostId(post.id ?? "")}
                      style={{ marginTop: "16px" }}
                    >
                      Add Post Id
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDeletePostWrapper(post.id ?? "")}
                      style={{ marginTop: "16px", marginLeft: "8px" }}
                    >
                      Delete Post
                    </Button>
                    <Typography variant="body2" color="textSecondary">
                      {post.id && `ID: ${post.id}`}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </Paper>
      )}
      <Dialog open={openWarningModal} onClose={handleWarningModalClose}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            No center point location is set. The notification will be sent to
            every user. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarningModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleWarningModalConfirm} color="primary" autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const NotificationToolPageWrapper = () => (
  <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={placesLibs}>
    <NotificationToolPage />
  </LoadScript>
);

export default NotificationToolPageWrapper;
