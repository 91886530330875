import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Autocomplete, Libraries, LoadScript } from "@react-google-maps/api";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { emptyPost, toFormDate } from "./EventUtils";
import {
  fetchPosts,
  fetchVicinityUserId,
  handleAddPost,
  handleDeletePost,
  handleEditPost,
} from "../../firestore/queries";
import axios from "axios";

var ngeohash = require("ngeohash");
var geofire = require("geofire-common");

const localizer = momentLocalizer(moment);
const placesLibs: Libraries = ["places"];
const googleMapsApiKey =
  process.env.REACT_APP_ENV === "production"
    ? "AIzaSyBF90b6SXXIUcPhJAIHHM69emhKfX0JvLo"
    : "AIzaSyBF90b6SXXIUcPhJAIHHM69emhKfX0JvLo";

const EventsPage = () => {
  const [post, setPost] = useState<EventPost>({
    ...emptyPost,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [posts, setPosts] = useState<EventPost[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<EventPost[]>([]);
  const [deleteId, setDeleteId] = useState("");
  const [wysiwygMode, setWysiwygMode] = useState(false);
  const [placeDisabled, setPlaceDisabled] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingPostId, setEditingPostId] = useState<string | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [filterLocation, setFilterLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [filterFormVisible, setFilterFormVisible] = useState(false);
  const placesAutcompleteRefForm = useRef<any>(null);
  const placesAutocompleteRef = useRef<any>(null);
  const [vicinityUserId, setVicinityUserId] = useState<string | null>(null);

  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    fetchVicinityUserId().then((vicinityUserId) => {
      setVicinityUserId(vicinityUserId);
      console.log("vicinityUserId", vicinityUserId);
      fetchPostsWrapper(vicinityUserId);
    });
  }, []);

  useEffect(() => {
    if (filterLocation) {
      filterPostsByLocation(filterLocation);
    } else {
      setFilteredPosts(posts);
    }
  }, [filterLocation, posts]);

  const fetchPostsWrapper = async (userId?: string) => {
    setLoading(true);
    const posts: EventPost[] = await fetchPosts(
      true,
      userId ?? vicinityUserId ?? ""
    );
    setLoading(false);
    setPosts(posts);
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log("handle change name value", name, value);
    setPost({ ...post, [name]: value });
    if (name === "imageUrl") {
      setImagePreview(value);
    }
  };

  const handleAddPostWrapper = async () => {
    if (!validatePost()) return;
    await handleAddPost(
      post,
      wysiwygMode,
      quillRef,
      fetchPostsWrapper,
      setMessage,
      setLoading,
      vicinityUserId ?? ""
    );
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleEditPostWrapper = async () => {
    if (!validatePost()) return;
    await handleEditPost(
      post,
      wysiwygMode,
      quillRef,
      editingPostId,
      fetchPostsWrapper,
      setMessage,
      setLoading
    );
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleDeletePostWrapper = async (postId: string) => {
    await handleDeletePost(
      postId,
      setLoading,
      setMessage,
      fetchPostsWrapper,
      setDeleteId
    );
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleEditButtonClick = (post: EventPost) => {
    const editPost = { ...post };
    console.log("edit post", editPost);
    const startTime = new Date(editPost.startTime);
    const fiftyYearsFromNow = new Date();
    fiftyYearsFromNow.setFullYear(fiftyYearsFromNow.getFullYear() + 50);

    setPost({
      ...editPost,
      startTime: toFormDate(startTime),
      endTime:
        editPost.endTime && new Date(editPost.endTime) < fiftyYearsFromNow
          ? toFormDate(new Date(editPost.endTime as string))
          : "",
    });
    setImagePreview(post.imageUrl);
    setEditing(true);
    setEditingPostId(post?.id ?? "");
    placesSetValueForm(post.place);
    setPlaceDisabled(true);
    if (typeof post.content === "object") {
      setWysiwygMode(true);
    }
  };

  const handleCancelEdit = () => {
    window.location.reload();
  };

  const handleWysiwygToggle = () => {
    if (wysiwygMode && post.content) {
      setConfirmDialogOpen(true);
    } else {
      setWysiwygMode(!wysiwygMode);
    }
  };

  const handleConfirmDialogClose = (confirm: boolean) => {
    setConfirmDialogOpen(false);
    if (confirm) {
      setPost({ ...post, content: "" });
      setWysiwygMode(false);
    }
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "placeFilterEvents",
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 1000,
  });

  const {
    ready: placesReadyForm,
    value: placesValueForm,
    suggestions: { status: placesStatusForm, data: placesDataForm },
    setValue: placesSetValueForm,
    clearSuggestions: placesClearSuggestionsForm,
  } = usePlacesAutocomplete({
    callbackName: "placeFormEvents",
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 1000,
  });

  const handleSelect = async (address: string, placeName: string) => {
    try {
      placesSetValueForm(placeName);
      const results = await getGeocode({ address });
      console.log("Results: ", results);
      const { lat, lng } = await getLatLng(results[0]);
      const geohash = ngeohash.encode(lat, lng);
      const updatedPost = {
        ...post,
        place: placeName,
        placeAddress: address,
        location: {
          geohash,
          geopoint: {
            _latitude: lat,
            _longitude: lng,
          },
        },
      };
      delete updatedPost.id;
      setPost(updatedPost);
      setPlaceDisabled(true);
    } catch (error) {
      console.error("Error: ", error);
    }
    placesClearSuggestionsForm();
  };

  const handleFilterSelect = async (address: string, name: string) => {
    try {
      const results = await getGeocode({ address });
      console.log("Filter Results: ", results);
      const { lat, lng } = await getLatLng(results[0]);
      setFilterLocation({ lat, lng });
      setValue(name);
    } catch (error) {
      console.error("Error: ", error);
    }
    placesClearSuggestionsForm();
  };

  const filterPostsByLocation = (location: { lat: number; lng: number }) => {
    const radiusInM = 50 * 1609.34; // 50 miles in meters
    const center = [location.lat, location.lng];
    const filtered = posts.filter((post) => {
      const postLocation = [
        post.location.geopoint._latitude,
        post.location.geopoint._longitude,
      ];
      const distance = geofire.distanceBetween(center, postLocation) * 1000; // distance in meters
      return distance <= radiusInM;
    });
    setFilteredPosts(filtered);
  };

  const onPlacesLoadForm = (autocomplete: any) => {
    placesAutcompleteRefForm.current = autocomplete;
  };

  const onLoad = (autocomplete: any) => {
    placesAutocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = async () => {
    const place = await placesAutcompleteRefForm.current.getPlace();
    if (place?.geometry && place?.name) {
      await handleSelect(place.formatted_address, place.name);
    }
  };

  const onFilterPlaceChanged = async () => {
    const place = await placesAutocompleteRef.current.getPlace();
    if (place?.geometry && place?.name) {
      await handleFilterSelect(place.formatted_address, place.name);
    }
  };

  const handleCancelPlace = () => {
    setPost({ ...post, place: "", placeAddress: "" });
    setPlaceDisabled(false);
    placesSetValueForm("");
  };

  const handleToggleFilterForm = () => {
    setFilterFormVisible(!filterFormVisible);
    if (!filterFormVisible) {
      setFilterLocation(null);
    }
  };

  const events = filteredPosts.map((post) => {
    const start = new Date(post.startTime);
    return {
      title: post.title,
      start,
      end: start, // Use startTime for both start and end
      allDay: false,
      resource: post,
    };
  });

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
    ],
  };

  const validatePost = () => {
    if (!post.title || !post.place || !post.startTime || !post.content) {
      setMessage("Title, Place, Start Time, and Content are required fields.");
      return false;
    }

    if (post.endTime && typeof post.endTime === "string") {
      const startTime = new Date(post.startTime);
      const endTime = new Date(post.endTime);
      const imageUrlPattern = /jpeg|jpg|png/i;

      if (
        post.endTime &&
        (endTime <= startTime ||
          startTime.toDateString() !== endTime.toDateString())
      ) {
        setMessage("End Time must be after Start Time and on the same day.");
        return false;
      }

      if (!imageUrlPattern.test(post.imageUrl)) {
        setMessage("Image URL must contain jpeg, jpg, or png.");
        return false;
      }
    }

    return true;
  };

  return (
    <Container>
      <Button
        onClick={() => {
          window.location.href = "/admin";
        }}
      >
        Back to Admin Page
      </Button>
      <Button
        onClick={() => {
          window.location.href = "/admin/notificationtool";
        }}
      >
        Go to Notification Tool
      </Button>
      <Paper style={{ padding: "16px", marginBottom: "16px" }}>
        <Typography variant="h5" gutterBottom>
          {editing ? `Edit Event Post: ${post.id}` : "Add Event Post"}
        </Typography>
        {process.env.REACT_APP_ENV === "production" && (
          <Typography variant="body1" color="red" gutterBottom>
            PRODUCTION
          </Typography>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={wysiwygMode}
                  onChange={handleWysiwygToggle}
                  name="wysiwygMode"
                  color="primary"
                />
              }
              label="WYSIWYG Mode"
            />
          </Grid>
          <Grid container item xs={12} spacing={3}>
            {imagePreview && (
              <Grid item xs={12}>
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ maxHeight: 200, maxWidth: 400 }}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={6}
              paddingBottom={wysiwygMode === true ? 11 : 0}
            >
              {wysiwygMode ? (
                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  value={post.content}
                  onChange={(value) => setPost({ ...post, content: value })}
                  modules={modules}
                  style={{ height: "450px", width: "394px" }}
                />
              ) : (
                <TextField
                  name="content"
                  label="Content"
                  fullWidth
                  value={post.content}
                  onChange={handleFormChange}
                  multiline
                  rows={20}
                  style={{ height: "500px", width: "394px" }}
                />
              )}
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              spacing={2}
              alignContent={"flex-start"}
            >
              <Grid item xs={12}>
                <TextField
                  name="title"
                  label="Title"
                  fullWidth
                  value={post.title}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  onLoad={onPlacesLoadForm}
                  onPlaceChanged={onPlaceChanged}
                >
                  <TextField
                    name="place"
                    label="Place"
                    fullWidth
                    value={placesValueForm}
                    onChange={(e) => placesSetValueForm(e.target.value)}
                    disabled={!placesReadyForm || placeDisabled}
                  />
                </Autocomplete>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="placeAddress"
                  label="Place Address"
                  fullWidth
                  value={post.placeAddress}
                  disabled
                />
              </Grid>
              {placeDisabled && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancelPlace}
                  >
                    Cancel Place Selection
                  </Button>
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  name="imageUrl"
                  label="Image URL"
                  fullWidth
                  value={post.imageUrl}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="startTime"
                  label={`Start Time (${
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  })`}
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  value={post.startTime}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="endTime"
                  label={`End Time (${
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  })`}
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: (post.startTime as string)?.split("T")[0] + "T00:00",
                    max: (post.startTime as string) + "T23:59",
                  }}
                  fullWidth
                  value={post.endTime}
                  onChange={handleFormChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={editing ? handleEditPostWrapper : handleAddPostWrapper}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : editing ? (
                "Update Post"
              ) : (
                "Add Post"
              )}
            </Button>
            {editing && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancelEdit}
                  style={{ marginLeft: "16px" }}
                >
                  Cancel Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() =>
                    handleDeletePostWrapper(editingPostId as string)
                  }
                  style={{ marginLeft: "16px" }}
                >
                  Delete Post
                </Button>
                <h4>{`Post Id: ${post.id}`} </h4>
              </>
            )}
          </Grid>
        </Grid>
        {message && (
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginTop: "16px" }}
          >
            {message}
          </Typography>
        )}
      </Paper>

      <Button onClick={handleToggleFilterForm} style={{ marginBottom: "16px" }}>
        {filterFormVisible ? "Hide City Filter" : "Filter events by area?"}
      </Button>

      {filterFormVisible && (
        <Paper style={{ padding: "16px", marginBottom: "16px" }}>
          <p>
            Filter Events by Area: The location you choose is the center point.
            Posts within 50 miles of your selected location appear on the
            calendar
          </p>
          <Autocomplete onLoad={onLoad} onPlaceChanged={onFilterPlaceChanged}>
            <TextField
              label="City"
              fullWidth
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={!ready}
            />
          </Autocomplete>
        </Paper>
      )}

      <Paper style={{ padding: "16px" }}>
        <Typography variant="h4" gutterBottom>
          Posts Calendar
        </Typography>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onSelectEvent={(event: { resource: EventPost }) =>
            handleEditButtonClick(event.resource)
          }
        />
      </Paper>

      <Dialog
        open={confirmDialogOpen}
        onClose={() => handleConfirmDialogClose(false)}
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved changes in WYSIWYG mode. Are you sure you want to
            switch off WYSIWYG mode and lose these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmDialogClose(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmDialogClose(true)}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const EventsPageWrapper = () => (
  <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={placesLibs}>
    <EventsPage />
  </LoadScript>
);

export default EventsPageWrapper;

export interface EventPost {
  id?: string;
  title: string;
  content: string;
  place: string;
  placeAddress: string;
  imageUrl: string;
  dateCreated?: Date;
  startTime: string | Date; // ISO date string or JavaScript Date
  endTime: string | Date | null; // ISO date string or JavaScript Date
  formattedStartTime?: string; // Formatted date string for display
  formattedEndTime?: string; // Formatted date string for display
  userId: string;
  isEvent: boolean;
  location: {
    geohash: string;
    geopoint: {
      _latitude: number;
      _longitude: number;
    };
  };
}
